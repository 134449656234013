import gql from 'graphql-tag';

export const cancelSubscriptionMutation = gql`
mutation cancelSubscription(
  $id: Int,
  $subscription: String,
  $token: String
) {
  cancelSubscription(
    id: $id,
    subscription: $subscription,
    token: $token
  ) {
    success
  }
}
`
