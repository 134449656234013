import gql from 'graphql-tag';

export const registerMutation = gql`
  mutation createUser($email: String!, $password: String!, $country: String) {
    createUser(email: $email, password: $password, country: $country) {
      token
      id
      email
      activatedEmail
      premiumAccount
      premiumEnd
      subscription
      subscriptionId
      userProfile {
        categories
        topics
        learningTime
        name
        goal
      }
      userProgress {
        finishedLessons
        finishedCourses
        inProgress
        level
        exp
        lessonDoneAt
      }
      orders {
        id
        item
        userId
        payment
        status
        price
        createdAt
      }
    }
  }
`;

export const loginMutation = gql`
  mutation login($email: String!, $password: String!, $country: String) {
    login(email: $email, password: $password, country: $country) {
      token
      id
      email
      activatedEmail
      premiumAccount
      premiumEnd
      subscription
      subscriptionId
      userProfile {
        categories
        topics
        learningTime
        name
        goal
      }
      userProgress {
        finishedLessons
        finishedCourses
        inProgress
        level
        exp
        lessonDoneAt
      }
      orders {
        id
        item
        userId
        payment
        status
        price
        createdAt
      }
    }
  }
`;

export const generateRestoreMutation = gql`
  mutation generateRestore($email: String) {
    generateRestore(email: $email)
  }
`;

export const restorePasswordMutation = gql`
  mutation restorePassword($email: String, $hash: String) {
    restorePassword(email: $email, hash: $hash)
  }
`;

export const changePasswordMutation = gql`
  mutation changePassword($id: ID, $oldPassword: String, $newPassword: String) {
    changePassword(
      id: $id
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      id
      email
    }
  }
`;

export const sendVerifyEmailMutation = gql`
  mutation sendVerifyEmail($id: ID, $email: String) {
    sendVerifyEmail(id: $id, email: $email) {
      id
      email
    }
  }
`;

export const verifyEmailMutation = gql`
  mutation verifyEmail($email: String, $hash: String) {
    verifyEmail(email: $email, hash: $hash) {
      id
      email
      activatedEmail
    }
  }
`;

export const updateUserMutation = gql`
  mutation updateUser(
    $id: ID!
    $field: String
    $fieldValue: String
    $token: String
  ) {
    updateUser(id: $id, field: $field, fieldValue: $fieldValue, token: $token) {
      id
      email
      activatedEmail
      premiumAccount
      premiumEnd
      subscription
      subscriptionId
      userProfile {
        categories
        topics
        learningTime
        name
        goal
      }
      userProgress {
        finishedLessons
        finishedCourses
        inProgress
        level
        exp
        lessonDoneAt
      }
      orders {
        id
        item
        userId
        payment
        status
        price
        createdAt
      }
    }
  }
`;

export const updateUserProgressMutation = gql`
  mutation updateProgress(
    $id: ID!
    $field: String
    $fieldValue: String
    $token: String
  ) {
    updateProgress(
      id: $id
      field: $field
      fieldValue: $fieldValue
      token: $token
    ) {
      finishedLessons
      finishedCourses
      inProgress
      level
      exp
      lessonDoneAt
    }
  }
`;

export const inviteFriendsMutation = gql`
  mutation inviteFriends($id: ID, $email: String, $emails: String) {
    inviteFriends(id: $id, email: $email, emails: $emails) {
      success
    }
  }
`;

export const subscribeMutation = gql`
  mutation subscribe($email: String) {
    subscribe(email: $email)
  }
`;

export const createLogMutation = gql`
  mutation createLog($type: String, $value: String, $id: ID, $note: String) {
    createLog(type: $type, value: $value, id: $id, note: $note) {
      success
    }
  }
`;
