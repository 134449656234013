export const getCookie = (cname) => {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const clearCookies = () => {
  document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

export const setCookies = (token, email, id, expireDate) => {
  document.cookie = `token=${token}; expires=${expireDate}; path=/`;
  document.cookie = `email=${email}; expires=${expireDate}; path=/`;
  document.cookie = `id=${id}; expires=${expireDate}; path=/`;
};

export const acceptGDPRCookie = (result, expireDate) => {
  document.cookie = `gdpr1.1=${
    result === true ? true : ""
  }; expires=${expireDate}; path=/`;
};
