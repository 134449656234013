import React, { Component } from "react";

import { Provider } from "react-redux";

import "./App.css";
import "./styles/app.scss";
import Main from "./components/main/main";

class OldApp extends Component {
  render() {
    return <Main />;
  }
}

export default OldApp;
