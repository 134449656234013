export function renderDescription(status) {
  switch (status) {
    case "paid":
    case "paid-cancelled":
      return "Thank you, all is fine.";
    case "risk-pending":
      return "Your transaction was recognized as risky, wait until we investigate it, please.";
    case "risk-accepted":
      return "After our investigation we accepted your transaction, wait for info about success payment, please.";
    case "risk-rejected":
      return "Unfortunately, we couldn't accept your payment. Try again, please.";
    default:
      return "";
  }
}

export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function formatDate(date, hours) {
  const d = new Date(date);
  if (hours) {
    return `${d.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    })} ${d.toLocaleTimeString("en-GB")}`;
  } else {
    return d.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
  }
}

export const checkLessonIsLast = (course, lesson, user) => {
  if (!user.userProgress.finishedCourses.includes(String(course.id))) {
    const restLessons = course.lessons.filter((item) => {
      return String(item.id) !== String(lesson.id);
    });
    for (let i = 0; i < restLessons.length; i++) {
      if (
        !user.userProgress.finishedLessons.includes(String(restLessons[i].id))
      ) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
};

export const checkUnlocked = (user, lesson, lessons) => {
  const { finishedLessons } = user.userProgress;
  const { lessonNumber } = lesson;
  if (lessonNumber !== 1) {
    const needToFinish = lessons.find(
      (item) => item.lessonNumber === Number(lessonNumber - 1)
    );
    if (needToFinish) {
      return finishedLessons.includes(String(needToFinish.id));
    }
  }
  return true;
};

export const findSlideByNumber = (slides, number) => {
  return slides.find((slide) => {
    return String(slide.slideNumber) === String(number);
  });
};

export const addFileUrl = (string) => {
  if (string) {
    const regex = /{duomly-files-url}|DUOMLY-S3-URL-SHORTCODE/g;
    const withUrl = string.replace(regex, process.env.REACT_APP_S3_URL);
    return withUrl;
  }
};

export const expTable = {
  1: 100,
  2: 200,
  3: 400,
  4: 800,
  5: 1500,
  6: 2500,
  7: 3500,
  8: 5000,
  9: 7500,
  10: 10000,
  11: 15000,
  12: 22500,
  13: 33750,
  14: 50625,
  15: 75900,
  16: 113900,
  17: 170800,
  18: 250000,
  19: 384000,
  20: 575000,
  21: 865000,
};

export const getCourseCategory = (category) => {
  return category;
};
