import gql from 'graphql-tag';

export const getUserQuery = gql`
  query User($id: ID!) {
    findUserById(id: $id) {
      id
      email
      activatedEmail
      premiumAccount
      premiumEnd
      subscription
      subscriptionId
      userProfile {
        categories
        topics
        learningTime
        name
      }
      userProgress {
        finishedLessons
        finishedCourses
        inProgress
        level
        exp
        lessonDoneAt
      }
      orders {
        id
        item
        userId
        payment
        status
        price
        createdAt
      }
    }
  }
`;
