import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { store } from "./store";

const initialState = {
  user: null,
  userLoader: false,
  location: null,
  verifiedOrder: null,
  verifiedEmailRes: false,
  restorePassword: {
    sent: false,
    success: false,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUser: (state, action) => {
      state.user = process.env.REACT_APP_FREE_ACCOUNT
        ? {
            ...action.payload,
            premiumAccount: false,
          }
        : action.payload;
      state.userLoader = false;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    addOrderToUser: (state, action) => {
      const orders = [].concat(state.user.orders);
      orders.push(action.payload);
      state.user = {
        ...state.user,
        orders: orders,
      };
    },
    setUserLoader: (state, action) => {
      state.userLoader = action.payload;
    },
    cancelSubscriptionSuccess: (state, action) => {
      state.user = {
        ...state.user,
        subscription: false,
      };
    },
    verifyOrderSuccess: (state, action) => {
      state.verifiedOrder = action.payload;
    },
    verifyEmailSuccess: (state, action) => {
      state.user = {
        ...state.user,
        activatedEmail: true,
      };
      state.verifiedEmailRes = action.payload;
    },
    restorePasswordResult: (state, action) => {
      state.restorePassword = {
        sent: true,
        success: action.payload,
      };
    },
    setUserProgress: (state, action) => {
      const user = {
        ...state.user,
        userProgress: action.payload,
      };
      state.user = user;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getUser,
  setLocation,
  addOrderToUser,
  setUserLoader,
  cancelSubscriptionSuccess,
  verifyOrderSuccess,
  verifyEmailSuccess,
  restorePasswordResult,
  setUserProgress,
} = userSlice.actions;

export default userSlice.reducer;
