import React, { Component } from "react";

import { connect } from "react-redux";
import { addDays } from "../../../../lib/utils";
import { acceptGDPRCookie } from "../../../../lib/cookiesHandler";
import { setGdpr } from "../../../../../redux/notificationsSlice";
import CustomButton from "../../../../../components/customButton";
import { Link } from "react-router-dom/cjs/react-router-dom";

class Gdpr extends Component {
  setGdpr(result) {
    if (result) {
      const expireDate = addDays(new Date(), 700).toUTCString();
      acceptGDPRCookie(result, expireDate);
    }
    this.props.setGdpr(result);
  }
  render() {
    return (
      <div className={`dialog-gdpr cookies`}>
        <div className={`ui message compact cookies`}>
          <React.Fragment>
            <div className="content">
              <p className="title">Dear valued user,</p>
              <p>
                Before you embark on your journey with our app, we want to
                ensure transparency and trust between us. To make this
                experience smooth and secure, we kindly ask you to review and
                accept our <Link to="/terms">Terms & Conditions</Link> and{" "}
                <Link to="/privacy">Privacy Policy</Link>.
              </p>
              <p>
                <Link to="/terms">Terms & Conditions:</Link> By using our app,
                you agree to abide by the terms and conditions outlined in this
                document. This includes respecting the intellectual property,
                using the app responsibly, and complying with all applicable
                laws and regulations.
              </p>
              <p>
                <Link to="/privacy">Privacy Policy:</Link> We take your privacy
                seriously. Our Privacy Policy explains how we collect, use, and
                protect your personal information. Rest assured, your data is
                handled with the utmost care and in accordance with the relevant
                data protection laws.
              </p>
              <p>
                Thank you for choosing our app. We look forward to providing you
                with a seamless and enjoyable experience.
              </p>
              <p>
                Best regards, <br /> Duomly's Team
              </p>
            </div>
            <div className="buttons">
              {/* <button
                className="ui button outline green"
                onClick={() => this.setGdpr(false)}
              >
                I don't accept
              </button> */}
              <CustomButton
                testId="accept-gdpr"
                handleClick={() => this.setGdpr(true)}
                text="I Accept"
              />
            </div>
          </React.Fragment>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setGdpr: (gdpr) => dispatch(setGdpr(gdpr)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Gdpr);
