import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { store } from "./store";

const initialState = {
  loading: true,
  courses: [],
  categories: [],
  topics: [],
};

export const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    getContentStart: (state, action) => {
      state.loading = true;
    },
    getCourses: (state, action) => {
      const courses = [].concat(state.courses);
      for (let course of action.payload) {
        const found = courses.find(
          (item) => String(item.id) === String(course.id)
        );
        if (!found) {
          courses.push(course);
        }
      }
      state.courses = courses;
      state.loading = false;
    },
    getSingleLessonContent: (state, action) => {
      const courses = [].concat(state.courses).filter((item) => {
        return String(item.id) !== String(action.payload.id);
      });
      courses.push(action.payload);
      state.courses = courses;
      state.loading = false;
    },
    getCourse: (state, action) => {
      const courses = [].concat(state.courses).filter((item) => {
        return String(item.id) !== String(action.payload.id);
      });
      courses.push(action.payload);
      state.courses = courses;
      state.loading = false;
    },
    getPaths: (state, action) => {
      state.paths = action.payload;
    },
    getTopics: (state, action) => {
      state.topics = action.payload;
    },
    getCategories: (state, action) => {
      const item = action.payload.find((item) => item.en === "IT & AI");
      const sorted = []
        .concat(action.payload)
        .filter((item) => item.en !== "IT & AI");
      sorted.push(item);
      state.categories = sorted;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getContentStart,
  getCourses,
  getSingleLessonContent,
  getCourse,
  getPaths,
  getCategories,
  getTopics,
} = contentSlice.actions;

export default contentSlice.reducer;
