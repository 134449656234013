import React, { useEffect } from "react";
import "./loader.scss";

const Loader = ({ type }) => {
  useEffect(() => {
    let time = 0;
    const timer = setInterval(() => {
      time += 1;
      if (time > 10 && type !== "image") {
        // ONLY FOR NOW
        // window.location.reload();
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div className="loader-component">
      <div className="ui active big centered inline loader"></div>
    </div>
  );
};

export default Loader;
