import client from "../graphql/client";
import { cancelSubscriptionMutation } from "../graphql/mutations/subscriptionMutations";
import {
  changePasswordMutation,
  sendVerifyEmailMutation,
  subscribeMutation,
  generateRestoreMutation,
  verifyEmailMutation,
  inviteFriendsMutation,
  loginMutation,
  registerMutation,
  restorePasswordMutation,
  createLogMutation,
} from "../graphql/mutations/userMutations";
import { verifyOrderQuery } from "../graphql/queries/orderQueries";
import { getUserQuery } from "../graphql/queries/userQueries";
import {
  clearCookies,
  getCookie,
  setCookies,
} from "../oldApp/lib/cookiesHandler";
import { addDays } from "../oldApp/lib/utils";
import { setComponentLoader, setPageLoader } from "../redux/loadersSlice";
import { hideModal } from "../redux/modalsSlice";
import { addNotification } from "../redux/notificationsSlice";
import { store } from "../redux/store";
import {
  cancelSubscriptionSuccess,
  getUser,
  restorePasswordResult,
  setUserLoader,
  verifyEmailSuccess,
  verifyOrderSuccess,
} from "../redux/userSlice";

export function register(email, password, country) {
  clearCookies();

  store.dispatch(setComponentLoader(true));
  client
    .mutate({
      mutation: registerMutation,
      variables: { email, password, country },
    })
    .then((response) => {
      const expireDate = addDays(new Date(), 7).toUTCString();
      setCookies(
        response.data.createUser.token,
        response.data.createUser.email,
        response.data.createUser.id,
        expireDate
      );

      let user = response.data.createUser;
      delete user.token;
      delete user.isLoggedIn;
      store.dispatch(getUser(user));
      store.dispatch(setComponentLoader(false));
      window.location.reload();
    })
    .catch((err) => {
      store.dispatch(setComponentLoader(false));
      const response =
        err?.graphQLErrors[0]?.extensions?.exception?.response?.error;
      store.dispatch(
        addNotification({
          type: "error",
          text: response ? response : "Something went wrong",
        })
      );
    });
}

export function login(email, password, country) {
  clearCookies();
  client
    .mutate({
      mutation: loginMutation,
      variables: { email, password, country },
    })
    .then((response) => {
      // this.props.onLogin(null);
      const expireDate = addDays(new Date(), 7).toUTCString();
      setCookies(
        response.data.login.token,
        response.data.login.email,
        response.data.login.id,
        expireDate
      );
      let user = response.data.login;
      delete user.token;
      delete user.isLoggedIn;
      store.dispatch(getUser(user));
      store.dispatch(setComponentLoader(false));
      window.location.reload();
    })
    .catch((error) => {
      store.dispatch(setComponentLoader(false));
      store.dispatch(
        addNotification({ type: "error", text: "Wrong username or password." })
      );
    });
}

export function askForUser(renew) {
  if (!renew) {
    store.dispatch(setUserLoader(true));
  }
  const id = getCookie("id");
  if (id) {
    client
      .query({
        query: getUserQuery,
        variables: { id },
      })
      .then((res) => {
        const user = res.data.findUserById;
        if (user) {
          store.dispatch(getUser(user));
          if (!renew) {
            store.dispatch(setUserLoader(false));
          }
        }
      })
      .catch((err) => {
        if (!renew) {
          store.dispatch(setUserLoader(false));
        }
      });
  } else {
    if (!renew) {
      store.dispatch(setUserLoader(false));
    }
  }
}

export function cancelSubscription(id, subscription) {
  const token = getCookie("token");
  if (token && id) {
    client
      .mutate({
        mutation: cancelSubscriptionMutation,
        variables: { id, subscription, token },
      })
      .then((res) => {
        const data = res?.data?.cancelSubscription?.success;
        if (data) {
          store.dispatch(
            addNotification(
              "success",
              "Your subscription has ben cancelled, wait for email with confirmation, please."
            )
          );
          store.dispatch(cancelSubscriptionSuccess());
        } else {
          store.dispatch(
            addNotification({
              type: "error",
              text: "Something went wrong, try again",
            })
          );
        }
      })
      .catch((err) => {
        const response =
          err?.graphQLErrors[0]?.extensions?.exception?.response?.error;
        store.dispatch(
          addNotification({
            type: "error",
            text: response ? response : "Something went wrong",
          })
        );
        // TODO: handle error
      });
  } else {
    // TODO: handle error
    store.dispatch(
      addNotification({
        type: "error",
        text: "Something went wrong, try again",
      })
    );
  }
}

export function verifyOrder(id, hash) {
  client
    .query({
      query: verifyOrderQuery,
      variables: { id, hash },
    })
    .then((res) => {
      if (
        res.data &&
        res.data.verifyOrder &&
        res.data.verifyOrder.status !== "created"
      ) {
        store.dispatch(verifyOrderSuccess(res.data.verifyOrder));
        //  store.dispatch(askForUser(true));
      } else {
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    })
    .catch((err) => {
      // TODO: handle error
    });
  // } else {
  //   // TODO: handle error
  // }
}

export function changePassword(id, oldPassword, newPassword) {
  client
    .mutate({
      mutation: changePasswordMutation,
      variables: { id, oldPassword, newPassword },
    })
    .then((res) => {
      const data = res?.data?.changePassword?.id;
      if (data) {
        store.dispatch(
          addNotification({ type: "success", text: "Password changed" })
        );
      }
    })
    .catch((err) => {
      const response =
        err?.graphQLErrors[0]?.extensions?.exception?.response?.error;
      store.dispatch(
        addNotification({
          type: "error",
          text: response ? response : "Something went wrong",
        })
      );
    });
}

export function sendVerifyEmail(id, email, callback) {
  client
    .mutate({
      mutation: sendVerifyEmailMutation,
      variables: { id, email },
    })
    .then((res) => {
      const data = res?.data?.sendVerifyEmail?.id;
      if (data) {
        store.dispatch(
          addNotification({ type: "success", text: "Email sent" })
        );
      }
      callback();
    })
    .catch((err) => {
      const response =
        err?.graphQLErrors[0]?.extensions?.exception?.response?.error;
      store.dispatch(
        addNotification({
          type: "error",
          text: response ? response : "Something went wrong",
        })
      );
    });
}

export function verifyEmail(email, hash) {
  store.dispatch(setPageLoader(true));
  client
    .mutate({
      mutation: verifyEmailMutation,
      variables: { email, hash },
    })
    .then((res) => {
      const data = res?.data?.verifyEmail?.activatedEmail;
      if (data) {
        store.dispatch(verifyEmailSuccess());
      }
    })
    .catch((err) => {
      const response =
        err?.graphQLErrors[0]?.extensions?.exception?.response?.error;
      store.dispatch(
        addNotification({
          type: "error",
          text: response ? response : "Something went wrong",
        })
      );
    })
    .then(() => store.dispatch(setPageLoader(false)));
}

export function inviteFriends(id, email, emails, modal) {
  window.dataLayer.push({ event: "invite_friend" });
  client
    .mutate({
      mutation: inviteFriendsMutation,
      variables: { id, email, emails },
    })
    .then((res) => {
      const data = res?.data?.inviteFriends?.success;
      if (data) {
        store.dispatch(
          addNotification(
            "success",
            "Thank you, we will invite your friends to learn with you."
          )
        );
        store.dispatch(hideModal(modal));
      } else {
        store.dispatch(
          addNotification({
            type: "error",
            text: "Something went wrong, try again",
          })
        );
      }
    })
    .catch((err) => {
      const response =
        err?.graphQLErrors[0]?.extensions?.exception?.response?.error;
      store.dispatch(
        addNotification({
          type: "error",
          text: response ? response : "Something went wrong",
        })
      );
    });
}

export function subscribeToNewsletter(email) {
  client
    .mutate({
      mutation: subscribeMutation,
      variables: { email },
    })
    .then(() => {
      store.dispatch(
        addNotification({
          type: "success",
          text: "Thanks for your subscription, we will update you with the news!",
        })
      );
    })
    .catch((error) => {
      store.dispatch(
        addNotification({
          type: "error",
          text: "Something went wrong. Try again.",
        })
      );
    });
}

export function generateRestore(email) {
  client
    .mutate({
      mutation: generateRestoreMutation,
      variables: { email },
    })
    .then((data) => {
      if (data.data.generateRestore) {
        store.dispatch(
          addNotification({
            type: "success",
            text: "We sent you a recovery email.",
          })
        );
      } else {
        store.dispatch(
          addNotification({
            type: "error",
            text: "Something went wrong. Try again.",
          })
        );
      }
    })
    .catch((error) => {
      store.dispatch(
        addNotification({
          type: "error",
          text: "Something went wrong. Try again.",
        })
      );
    });
}

export function restorePassword(email, hash) {
  client
    .mutate({
      mutation: restorePasswordMutation,
      variables: { email, hash },
    })
    .then(({ data }) => {
      store.dispatch(restorePasswordResult(data.restorePassword));
    })
    .catch((error) => {
      store.dispatch(restorePasswordResult(false));
    });
}

export function createLog(type, value, id, note) {
  client
    .mutate({
      mutation: createLogMutation,
      variables: { type, value, id, note },
    })
    .then((response) => {})
    .catch((error) => {});
}
