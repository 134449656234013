import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { store } from "./store";

const initialState = {
  pageLoader: false,
  componentLoader: false,
};

export const loadersSlice = createSlice({
  name: "loaders",
  initialState,
  reducers: {
    setPageLoader: (state, action) => {
      state.pageLoader = action.payload;
    },
    setComponentLoader: (state, action) => {
      state.componentLoader = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPageLoader, setComponentLoader } = loadersSlice.actions;

export default loadersSlice.reducer;
