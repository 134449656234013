import gql from 'graphql-tag';

export const getCurrenciesQuery = gql` 
  query Order {
    getCurrencies {
      blockExplorer,
      title,
      code,
      decimals,
      icon,
      maxConfirmations,
      rate,
    }
  }
`;

export const verifyOrderQuery = gql` 
  query Order($id: ID, $hash: String) {
    verifyOrder(id: $id, hash: $hash) {
      id,
      status
    }
  }
`;