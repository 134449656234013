import { configureStore } from "@reduxjs/toolkit";

import notificationsReducer from "./notificationsSlice";
import contentSlice from "./contentSlice";
import learningSlice from "./learningSlice";
import loadersSlice from "./loadersSlice";
import modalsSlice from "./modalsSlice";
import userSlice from "./userSlice";

export const store = configureStore({
  reducer: {
    content: contentSlice,
    learning: learningSlice,
    loaders: loadersSlice,
    modals: modalsSlice,
    user: userSlice,
    notifications: notificationsReducer,
  },
});
