import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { store } from "./store";

const initialState = {
  gdprAccepted: null,
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action) => {
      const notification = {
        id: nanoid(),
        text: action.payload.text,
        type: action.payload.type,
      };
      const updatedNotifications = state.notifications;
      updatedNotifications.push(notification);
      if (action.payload.type !== "activateEmail") {
        setTimeout(() => {
          store.dispatch(hideNotification(notification));
        }, 3000);
      }
      state.notifications = updatedNotifications;
    },
    setGdpr: (state, action) => {
      state.gdprAccepted = action.payload;
    },
    hideNotification: (state, action) => {
      const notifications = state.notifications.filter((notification) => {
        return notification.id !== action.payload.id;
      });
      state.notifications = notifications;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addNotification, hideNotification, setGdpr } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
