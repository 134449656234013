import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as qs from "qs";
import Loader from "../loader/loader";
import { getCookie } from "../../lib/cookiesHandler";
import { setGdpr } from "../../../redux/notificationsSlice";
import Gdpr from "../shared/popups/gdpr/gdpr";
import { setLocation } from "../../../redux/userSlice";
import { askForUser } from "../../../api/user";

const LoggedOut = React.lazy(() => import("../loggedOut"));
const LoggedIn = React.lazy(() => import("../loggedIn"));
const Notifications = React.lazy(() =>
  import("../shared/popups/notifications")
);
const Modal = React.lazy(() => import("../shared/popups/modals/modal"));

class Main extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loadingLocation: true,
    };
  }
  // Move to actions and use our loaders
  getLocation() {
    const that = this;
    axios
      .get(
        "https://api.ipdata.co/?api-key=7dd71a6718f87765a9ca28950443dbeb565bf6cccb009f02c45e3152"
      )
      .then((response) => {
        setLocation(response.data);
      })
      .catch((error) => {})
      .then(() => {
        this.setState({
          loadingLocation: false,
        });
      });
  }

  checkCode() {
    const parsed = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    if (parsed && parsed.code) {
      sessionStorage.setItem("discountCode", parsed.code);
    }
  }

  checkgdpr = () => {
    const accepted = getCookie("gdpr1.1");
    if (accepted) {
      this.props.setGdpr(true);
    }
  };

  componentDidMount() {
    this.checkgdpr();
    askForUser();
    // Only for V2
    this.getLocation();
    this.checkCode();
    // TODO: delete when experiment content Variant finishes
    // localStorage.setItem("content-variant", "B");
  }

  stopLoading() {
    this.setState({
      loading: false,
    });
  }

  renderLoader = () => {
    return <Loader />;
  };

  render() {
    if (!this.props.user) {
    }

    const { loadingLocation } = this.state;
    const { loading, userLocation, user } = this.props;
    if (!loading && !loadingLocation) {
      return (
        <main>
          <div>
            {!user && (
              <React.Suspense fallback={<Loader />}>
                <LoggedOut />
              </React.Suspense>
            )}
            {user && (
              <React.Suspense fallback={<Loader />}>
                <LoggedIn />
              </React.Suspense>
            )}
            {this.props.notificationsGdpr !== true &&
              this.props.notificationsGdpr !== false && <Gdpr />}
            <React.Suspense fallback={<Loader />}>
              <Notifications />
            </React.Suspense>
            {this.props.modal && (
              <React.Suspense fallback={<Loader />}>
                <Modal />
              </React.Suspense>
            )}
          </div>
        </main>
      );
    } else {
      return this.renderLoader();
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  notificationsGdpr: state.notifications.gdprAccepted,
  modal: state.modals.modal,
  userLocation: state.user.location,
  loading: state.user.userLoader,
});

const mapDispatchToProps = (dispatch) => ({
  setGdpr: (gdpr) => dispatch(setGdpr(gdpr)),
  setLocation: (location) => dispatch(setLocation(location)),
  // askForUser: (renew) => dispatch(askForUser(renew)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
