import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modal: null,
};

export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    hideModal: (state, action) => {
      state.modal = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setModal, hideModal } = modalsSlice.actions;

export default modalsSlice.reducer;
