import React from "react";
import CustomButton from "./customButton";
import sadPanda from "../assets/img/sad-panda.svg";
function FloatingNotification({
  position,
  sentiment,
  withButton,
  headline,
  text,
  fullWidth,
  handleClick,
  big,
}) {
  return (
    <div className="w-full">
      <div
        className={`flex flex-col ${position === "center" && "items-center"}`}
      >
        <div className="items-center">
          <img
            src={sentiment === "negative" ? sadPanda : sadPanda}
            // style={{maxWidth: '100px'}}
            className={`-mb-24 max-w-[250px] ${
              position !== "center" && "ml-20"
            }`}
          />
        </div>
        <div
          className={`w-0 h-0 border-l-[15px] border-l-transparent border-b-[18px] border-b-white border-r-[15px] border-r-transparent ml-4 ${
            position !== "center" && "ml-24"
          }`}
        />
        <div
          className={"bg-white rounded-2xl w-full p-4" + (big ? " p-8" : "")}
        >
          <span
            className={
              "block text-center text-2xl font-bold" +
              (sentiment === "negative" ? " text-red-500" : " text-gray-700") +
              (big ? " text-4xl" : "")
            }
          >
            {headline}
          </span>
          {withButton && (
            <div className="mt-4">
              {sentiment === "positive" ? (
                <CustomButton text={text} handleClick={handleClick} />
              ) : (
                <CustomButton
                  text={text}
                  handleClick={handleClick}
                  color="red"
                />
              )}
            </div>
          )}
          {!withButton && (
            <span
              className={
                "block text-center text-gray-700 text-lg" +
                (big ? " text-xl mt-4" : "")
              }
            >
              {text}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

// Triangle left;
// <div class="w-0 h-0
//   border-t-[50px] border-t-transparent
//   border-r-[75px] border-r-blue-500
//   border-b-[50px] border-b-transparent">
// </div>

export default FloatingNotification;
