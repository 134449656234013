import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { store } from "./store";

const initialState = {
  code: null,
};

export const learningSlice = createSlice({
  name: "learning",
  initialState,
  reducers: {
    setCode: (state, action) => {
      state.code = action.payload;
    },
    resetSlide: (state, action) => {
      state.code =
        action.payload && action.payload.trim().length > 0
          ? action.payload
          : null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCode, resetSlide } = learningSlice.actions;

export default learningSlice.reducer;
