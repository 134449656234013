import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
// import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";

const CustomButton = ({
  text,
  handleClick,
  color,
  withoutIcon,
  textColor,
  disabled,
  children,
  testId,
}) => {
  const [buttonHeight, setButtonHeight] = useState(41);
  const [buttonColor, setButtonColor] = useState("null");
  const [shadow, setShadow] = useState("null");

  useEffect(() => {
    if (color === "red") {
      setButtonColor("bg-red-500");
    } else if (color === "blue") {
      setButtonColor("bg-blue-500");
    } else if (color === "white") {
      setButtonColor("bg-white");
    } else if (color === "gray") {
      setButtonColor("bg-gray-200");
    } else {
      setButtonColor("bg-green-500");
    }

    if (color === "red") {
      setShadow("bg-red-600");
    } else if (color === "blue") {
      setShadow("bg-blue-600");
    } else if (color === "white") {
      setShadow("bg-gray-200");
    } else if (color === "gray") {
      setShadow("bg-gray-400");
    } else {
      setShadow("bg-green-600");
    }
  }, [color]);

  const handleButtonClick = () => {
    handleClick();
    setButtonHeight(41);
  };

  return (
    <div
      className={
        "relative text-center w-full cursor-pointer rounded-[15px] h-[46px] " +
        shadow
      }
    >
      <div style={{ height: buttonHeight }}>
        <div
          aria-label={testId}
          style={{ height: buttonHeight }}
          onMouseDown={() => {
            if (!disabled) {
              setButtonHeight(46);
            }
          }}
          onMouseUp={() => {
            if (!disabled) {
              handleButtonClick();
            }
          }}
          className={
            "z-40 px-12 rounded-2xl flex flex-row justify-center items-center " +
            buttonColor
          }
        >
          {text && (
            <p
              style={{ color: textColor ? textColor : "#ffffff", margin: 0 }}
              className="text-white mb- font-bold uppercase text-base"
            >
              {text}
            </p>
          )}
          {children}
          {!withoutIcon && (
            <div
              style={{ height: 25, width: 25 }}
              className={
                "text-white flex absolute right-2 text-sm rounded-full items-center justify-center " +
                shadow
              }
            >
              <FontAwesomeIcon
                className="text-white"
                icon={faArrowRight}
                color="white"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomButton;
